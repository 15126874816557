import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { appConfig } from '../app.config';
import { OrderTypes } from '../models/order-types';

@Injectable({
  providedIn: 'root'
})
export class NgxIndexdbServiceService {

  constructor(private http: HttpClient) { }

  // async startIndexdbStoreFunction() {
  //   const promisesArray: any[] = [];
  //   await promisesArray.push(this.getTenderProfile());
  //   await promisesArray.push(this.getreasons());
  //   await promisesArray.push(this.getplucategorylist());
  //   await promisesArray.push(this.getcustomerList());
  //   await promisesArray.push(this.getPluMenuItems());
  //   await promisesArray.push(this.getTenderTypeListItems());

  //   // await promisesArray.push(this.getStore());
  //   await Promise.all(promisesArray)
  //     .then((res) => {
  //       console.log("IndexDB data added..!");
  //     }, (firstErr) => {
  //     });
  // }



  // getTenderProfile() {
  //   return new Promise(resolve => {
  //     this.http.post(appConfig.apiUrl + '/tenderlist', '').subscribe(data => {
  //       let ordersTypes: OrderTypes[] = [];
  //       ordersTypes = data['data'];
  //       this.indexdbservice.clear('tenderprofiles');
  //       this.indexdbservice.add('tenderprofiles', ordersTypes).toPromise()
  //         .then(
  //           res => {
  //             resolve(res);
  //           },
  //           error => {
  //             console.log(error);
  //           });
  //     }, (err) => {
  //       resolve(err);
  //     });
  //   });
  // }

  // getreasons() {
  //   return new Promise(resolve => {
  //     var data = {
  //       terminal_id: localStorage.getItem('terminal_id'),
  //       type: "0"
  //     }
  //     this.http.post(appConfig.apiUrl + '/getreasons', data).subscribe(data => {
  //       this.indexdbservice.clear('reasons');
  //       let reasons = data['data'];
  //       reasons.forEach(reason => {
  //         this.indexdbservice.add('reasons', reason).toPromise()
  //           .then(
  //             res => {
  //               resolve(res);
  //             },
  //             error => {
  //               console.log(error);
  //             });
  //       });

  //     }, (err) => {
  //       resolve(err);
  //     });
  //   });
  // }

  // getplucategorylist() {
  //   return new Promise(resolve => {
  //     this.http.post(appConfig.apiUrl + '/plucategorylist', '').subscribe(data => {
  //       this.indexdbservice.clear('pluCategoryList');
  //       this.indexdbservice.add('pluCategoryList', data['data']).toPromise()
  //         .then(
  //           res => {
  //             resolve(res);
  //           },
  //           error => {
  //             console.log(error);
  //           });
  //     }, (err) => {
  //       resolve(err);
  //     });
  //   });
  // }

 

  // getcustomerList() {
  //   return new Promise(resolve => {
  //     this.http.post(appConfig.apiUrl + '/customerlist', '').subscribe(data => {
  //       this.indexdbservice.clear('customerList');
  //       this.indexdbservice.add('customerList', data['data']).toPromise()
  //         .then(
  //           res => {
  //             resolve(res);
  //           },
  //           error => {
  //             console.log(error);
  //           });
  //     }, (err) => {
  //       resolve(err);
  //     });
  //   });
  // }

  // getPluMenuItems() {
  //   return new Promise(resolve => {
  //     this.http.post(appConfig.apiUrl + '/plumenuitem', '').subscribe(data => {
  //       this.indexdbservice.clear('plumenuitem');
  //       this.indexdbservice.add('plumenuitem', data['data']).toPromise()
  //         .then(
  //           res => {
  //             resolve(res);
  //           },
  //           error => {
  //             console.log(error);
  //           });
  //     }, (err) => {
  //       resolve(err);
  //     });
  //   });
  // }

  // getTenderTypeListItems() {
  //   return new Promise(resolve => {
  //     this.http.post(appConfig.apiUrl + '/tendertypelist', { department_id: 1 }).subscribe(data => {
  //       this.indexdbservice.clear('tendertypelist');
  //       this.indexdbservice.add('tendertypelist', data['data']).toPromise()
  //         .then(
  //           res => {
  //             resolve(res);
  //           },
  //           error => {
  //             console.log(error);
  //           });
  //     }, (err) => {
  //       resolve(err);
  //     });
  //   });
  // }

  // getStore() {
  //   return new Promise(resolve => {
  //     this.http.post(appConfig.apiUrl + '/getstore', { department_id: 11 }).subscribe(data => {
  //       this.indexdbservice.clear('storedetail');
  //       this.indexdbservice.add('storedetail', data['data']).toPromise()
  //         .then(
  //           res => {
  //             resolve(res);
  //           },
  //           error => {
  //             console.log(error);
  //           });
  //     }, (err) => {
  //       resolve(err);
  //     });
  //   });
  // }



}
